const url = '/api/users'
const url_auxiliary = '/inactivate_users/'
export default (axios) => ({
  LoginUser(data) {
    return axios
      .post(`/api/login`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  createUser(data) {
    return axios
      .post(`${url}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getUsers(params) {
    return axios
      .get(url, { params })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getUser(id) {
    return axios
      .get(`${url}/${id}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  updateUser(data, id) {
    return axios
      .put(`${url}/${id}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  deleteUser(id) {
    return axios
      .delete(`${url}/${id}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  changeStateUser(data, id) {
    return axios
      .put(`${url_auxiliary}${id}/`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
})
