<template>
  <form @submit.prevent="login()" class="flex flex-col gap-5">
    <div class="text-left">
      <h2 class="text-2xl sm:text-3xl"><b>Iniciar sesión</b></h2>
      <p class="text-gray-400 sm:text-md">
        Ingrese con los datos suministrados al momento del registro
      </p>
    </div>
    <BaseInput
      label="Correo electrónico"
      class="-mt-px"
      v-model="email"
      placeholder="Ingrese su correo electrónico"
      autocomplete="username"
    />
    <BasePassword
      label="Ingrese su contraseña"
      class="-mt-px"
      v-model="password"
      placeholder="********"
      autocomplete="current-password"
    />
    <div
      class="-mt-2 text-left text-sm flex flex-col justify-center items-center sm:flex-row sm:justify-between"
    >
      <span class="flex justifycenter items-center text-xs"
        ><input
          type="checkbox"
          id="remember"
          class="mr-2 rounded"
          v-model="remember"
        />
        Recordarme</span
      >
      <router-link to="/recovery" class="my-1 text-xs"
        ><b>¿Olvidó su contraseña?</b></router-link
      >
    </div>
    <p v-if="feedback" class="text-sm text-red-500">{{ feedback }}</p>
    <Button
      type="submit"
      :block="true"
      :loading="loading"
      :disable="loading"
      style="primary"
    >
      <span class="mx-2">Iniciar Sesión</span>
    </Button>
  </form>
</template>
<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      feedback: '',
      loading: false,
      remember: false,
    }
  },
  methods: {
    login() {
      if (this.invalidForm()) return
      this.loading = true
      this.$store
        .dispatch('session/login', {
          email: this.email,
          correo: this.$validator.isEmail(this.email),
          password: this.password,
          remember: this.remember,
        })
        .then(() => {
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          if (error.code && error.code === 'auth/user-disabled') {
            this.feedback = 'El usuario se encuentra inactivo'
          } else {
            this.feedback = 'Usuario y/o contraseña no son validos'
          }
          this.loading = false
        })
    },
    invalidForm() {
      this.feedback = null
      if (
        this.$validator.isEmpty(this.email) ||
        this.$validator.isEmpty(this.password)
      ) {
        this.feedback = 'Verifique la información'
        return true
      }
      return false
    },
  },
}
</script>

<style></style>
