<template>
  <form @submit.prevent="createResponse" class="max-w-2xl">
    <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
      <BaseSelect
        label="Aceptación"
        :selectOptions="formOptions.optionsAcceptance"
        v-model="form.aceptacion_queja"
      />
      <BaseSelect
        label="Rectificación"
        :selectOptions="formOptions.optionsRectification"
        v-model="form.rectificacion_queja"
      />
    </div>
    <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
      <BaseSelect
        label="Desistimiento"
        :selectOptions="formOptions.optionsDesist"
        v-model="form.desistimiento_queja"
      />
      <BaseSelect
        label="Admisión"
        :selectOptions="formOptions.optionsAdmission"
        v-model="form.admision"
      />
    </div>
    <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
      <BaseSelect
        label="Favorabilidad"
        :selectOptions="formOptions.optionsFavorability"
        v-model="form.a_favor_de"
      />      
      <BaseSelect
        label="Motivo"
        :selectOptions="formOptions.optionsReasons"
        v-model="selectedReason"        
      />
    </div>
    <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
      <BaseSelect
        label="¿Tutela?"
        :selectOptions="formOptions.optionsYN"
        v-model="form.tutela"
      />
      <BaseSelect
        label="¿Queja express?"
        :selectOptions="formOptions.optionsYN"
        v-model="form.queja_expres"
      />
    </div>
    <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
      <BaseSelect
        label="Documentación de respuesta final"
        :selectOptions="formOptions.optionsYN"
        v-model="form.documentacion_rta_final"
      />
      <BaseSelect
        label="Estado de la queja"
        :selectOptions="formOptions.optionsStatus"
        v-model="form.estado_cod"
      />
    </div>
    
    <div v-if="showFraudFields" class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
      <BaseSelect
        label="Tipo de fraude"
        :selectOptions="formOptions.optionsTypeFraud"
        v-model="form.tipo_fraude"
        :required="true"
      />
      <BaseSelect
        label="Modalidad de fraude"
        :selectOptions="formOptions.optionsModalityFraud"
        v-model="form.modalidad_fraude"
        :required="true"
      />
      
      <BaseInput
        :type="'number'"
        label="Monto reconocido"
        placeholder="Ej: 100000"
        v-model="form.monto_reconocido"
        :required="true"     
        @input="filterInputMontoReconocido"   
      />

      <BaseInput
        :type="'number'"
        label="Monto reclamado"
        placeholder="Ej: 100000"
        v-model="form.monto_reclamado"
        :required="true"
        @input="filterInputMontoReclamado"
      />
    </div>
    <div class="w-full grid grid-cols-1">
      <DragDrop
        id="responseFiles"
        label="Archivos de soporte a la respuesta"
        buttonLabel="Adjuntar archivos de soporte"
        :file-list="files"
        @files-handler="setFiles"
      />
    </div>
    <div v-if="feedback">
      <p class="my-2 text-xs text-center text-red-500">{{ feedback }}</p>
    </div>
    <div
      class="w-full mt-8 mr-auto flex flex-col lg:flex-row-reverse lg:justify-end gap-4"
    >
      <Button
        type="submit"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
      >
        Guardar
      </Button>
      <Button
        type="button"
        :danger="true"
        :invert="true"
        :block="true"
        :disable="loading"
        @click.native="$router.go(-1)"
      >
        Cancelar
      </Button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      complaint_id: '',
      form: {
        a_favor_de: '',
        aceptacion_queja: '',
        rectificacion_queja: '',
        desistimiento_queja: '',
        admision: '',
        tutela: '',
        queja_expres: '',
        documentacion_rta_final: '',
        estado_cod: '',
        macro_motivo_cod: '',
        tipo_fraude: '',
        modalidad_fraude:'',
        monto_reconocido:'',
        monto_reclamado:'',
      },
      loading: false,
      feedback: '',
      files: [],
      reasonsToCheck: [9, 203, 215, 219, 908, 910, 916, 922, 940, 941],//Ids motivos fraude      
    }
  },
  computed: {
    ...mapState({ formOptions: 'form' }),
    selectedReason: {
      get() {
        if(this.form.macro_motivo_cod >0 && this.form.macro_motivo_cod <100){
          return '';
        }
        return this.form.macro_motivo_cod;
      },
      set(value) {
        this.form.macro_motivo_cod = Number(value);
      },
    },
    showFraudFields() {      
      return this.reasonsToCheck.includes(Number(this.form.macro_motivo_cod));
    },
  },
  created() {
    this.complaint_id = this.$route.params.complaint_id
    this.fetchComplaint()
  },
  methods: {
    async createResponse() {
      if (this.invalidForm()) return
      this.loading = true
      let response = await this.$api.complaints.updateComplaint(
        this.complaint_id,
        this.form,
        this.files
      )
      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        this.loading = false
        return
      }
      this.$showNotification('success', 'Se actualizó la queja correctamente')
      this.loading = false
      this.$router.go(-1)
    },
    async fetchComplaint() {
      const response = await this.$api.complaints.getComplaintById(
        this.complaint_id
      )
      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }
      this.form = {
        a_favor_de: response.data.a_favor_de || '',
        aceptacion_queja: response.data.aceptacion_queja || '',
        rectificacion_queja: response.data.rectificacion_queja || '',
        desistimiento_queja: response.data.desistimiento_queja || '',
        admision: response.data.admision || '',
        tutela: response.data.tutela || '',
        queja_expres: response.data.queja_expres || '',
        documentacion_rta_final: response.data.documentacion_rta_final || '',
        estado_cod: response.data.estado_cod || '',
        macro_motivo_cod: response.data.macro_motivo_cod || '',
      }
    },
    setFiles(files) {
      this.files = files
    },
    invalidForm() {
      if (!this.form.macro_motivo_cod) {
        this.$showNotification('error', 'Debe seleccionar un mótivo')
        return true
      }
      if (!this.form.estado_cod) {
        this.$showNotification('error', 'Debe seleccionar un estado')
        return true
      }
      if (this.form.estado_cod === '4' && !this.files.length) {
        this.$showNotification(
          'error',
          'Debe adjuntar como mínimo un archivo para poder cerrar la queja'
        )

        return true
      }
      if (this.showFraudFields) {
        if (!this.form.tipo_fraude) {
          this.$showNotification('error', 'Debe seleccionar un tipo de fraude')
          return true
        }
        if (!this.form.modalidad_fraude) {
          this.$showNotification('error', 'Debe seleccionar una modalidad de fraude')
          return true
        }
        if (!this.form.monto_reconocido) {
          this.$showNotification('error', 'Debe ingresar un monto reconocido')
          return true
        }
        if (!this.form.monto_reclamado) {
          this.$showNotification('error', 'Debe ingresar un monto reclamado')
          return true
        }        
      }
      return false
    },    
    filterInputMontoReconocido(value) {              
      this.form.monto_reconocido = value.replace(/[^0-9]/g, '');      
    },
    filterInputMontoReclamado(value) {      
      this.form.monto_reclamado = value.replace(/[^0-9]/g, '');      
    },
  },
}
</script>

<style></style>
